import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import copy from "copy-to-clipboard";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import axiosAPI from "./axios/axios";
import copyIcon from "../src/assets/copy-icon.png";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer,toast } from "react-toastify";
import BabydogeCharacter from "./assets/mint-character.svg"
import KatanainuLogo from "../src/assets/katanainu-logo.png"
function App() {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [walletError, setWalletError] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const [refCode, setRefCode] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    wallet: "",
    social: "",
    // referralCode: "",
    referralCode: "babydoge.katanainu.com?ref=",
  });
  console.log(refCode);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
    console.log("Some content just changed: ", formData);
  };

  const handleDefaultValueChange = (e) => {
    let value = e.target.value;
    if (value.includes("babydoge.katanainu.com?ref=")) {
      setFormData({ ...formData, referralCode: value });
    } else {
      setFormData({ ...formData, referralCode: "babydoge.katanainu.com?ref=" });
    }
  };
  const copyFunc = async () => {
    let url = "babydoge.katanainu.com?ref=" + refCode;
    copy(url);
    toast("Link Copied Successfully")
  };
  useEffect(() => {
    validateInput();
  }, [formData.wallet, formData.referralCode]);

  const validateInput = async (e) => {
    try {
      const { wallet, referralCode } = formData;
      if (wallet) {
        const validWallet = await axiosAPI.get(
          `/referral/checkWallet?wallet=${formData.wallet}`
        );

        if (validWallet.data.exists == true) {
          console.log("validWallet: ", validWallet.data);
          setWalletError(true);
        } else {
          setWalletError(false);
        }
      }

      if (referralCode.split("=")[1]) {
        const validLink = await axiosAPI.get(
          `/referral/checklink?link=${formData.referralCode.split("=")[1]}`
        );

        if (validLink.data.exists) {
          console.log("validLink: ", validLink);
          setLinkError(true);
        } else {
          setLinkError(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { name, wallet, social, referralCode } = formData;

    if (name && wallet && social && referralCode) {
      console.log("Form-Submited: ", formData);

      try {
        let refCode = formData.referralCode.split("=")[1];
        const reponse = await axiosAPI.post("/referral/create", {
          ...formData,
          referralCode: refCode,
        });
        setSubmitSuccess(true);
        setRefCode(reponse?.data?.data?.referral?.referralCode);
        setFormData({
          name: "",
          wallet: "",
          social: "",
          referralCode: "babydoge.katanainu.com?ref=",
        });
      } catch (err) {
        console.log(`Error: ${err.message}`);
      }
    } else {
      alert("Please fill all the fields to Sign Up");
    }
  };

  const { name, wallet, social, referralCode } = formData;
  return (
    <div className="App">
      <ToastContainer />

      <Container>
        <Row>
          <Col sm={12} md={12} lg={6} className="intro-sec">
            <div className="intro_container">
              <div className="intro_sub_section">
                <div className="heading_block">
                  <div className="logo">
                    <img
                      src={KatanainuLogo}
                      alt="logo"
                    />
                    <p>Powered by Katanainu.com</p>
                  </div>
                  <h2>Hi, welcome to the Babydoge-Katanainu</h2>
                  <div className="intro-desc">
                    We’re excited to have you become a Katanainu.com exclusive
                    partner!
                  </div>
                </div>
                <div className="intro_img">
                  <img
                    src={BabydogeCharacter}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} className="form-sec">
            <div className="form_container">
              <div className="form-heading"></div>
              <div className="form_desc">
                <h2>
                  Let’s create your referral code! Please answer these
                  questions.
                </h2>
                <p>This information will NOT be shared with anyone</p>
              </div>
              <div className="main_form">
                <div className="form_list">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Your full name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter full name"
                        name="name"
                        value={name}
                        // onChange={handleChange}
                        onInput={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Your ERC-20 Metamask wallet address for receiving
                        bonuses
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your ERC-20 Metamask wallet address"
                        name="wallet"
                        value={wallet}
                        onChange={handleChange}
                      />
                      <Form.Text id="walletAddressHelpBlock" muted>
                        {walletError
                          ? "This wallet address already existed. Try another one"
                          : ""}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Your social media channels links</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Youtube, Facebook, Twitter, Instagram, Discord"
                        name="social"
                        value={social}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Your referral link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="babydoge.katanainu.com?ref="
                        name="referralCode"
                        value={referralCode}
                        onChange={handleDefaultValueChange}
                      />
                      <Form.Text id="walletAddressHelpBlock" muted>
                        {linkError
                          ? "This referral code already existed. Try another one"
                          : ""}
                      </Form.Text>
                    </Form.Group>

                    {/* {optionalInput && (
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Who referred you?</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name of referrer"
                          name="referral"
                          value={referral}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    )} */}
                  </Form>
                </div>
                <button
                  disabled={linkError || walletError ? true : false}
                  className="submit_button"
                  onClick={(event) => handleSubmit(event)}
                >
                  Get my referral Link
                </button>
                <p className="submit_desc">
                  By creating my referral link, I agree with{" "}
                  <a target="_" href="#">
                    Terms and Conditions
                  </a>{" "}
                  of the Babydoge - Katana Inu influencer program
                </p>
                {submitSuccess ? (
                  <p className="submit_desc">
                    <div className="mt-auto pb-3 x60:pb-10 pl-3 x60:pl-10  z-10 ">
                      <p className="pb-2 text-[14px] text-[#E4E4E4]">
                        Your Referral Code:
                      </p>
                      <div className="relative w-[16rem] x60:w-[20rem] x1250:w-[16rem] x14:w-[20rem]">
                        <input
                          type="text"
                          disabled
                          value={`babydoge.katanainu.com?ref=${refCode}`}
                          style={{ padding: "2px" }}
                          className="px-4 py-2 border border-gray-600 rounded-[10px] pr-12 w-full bg-[#30373F] h-[3rem]"
                        />
                        <button onClick={copyFunc} className="copy_button">
                          <image
                            src={copyIcon}
                            alt="copy"
                            className="w-[1.2em] h-[1.2em]"
                          />
                          Copy Link
                        </button>
                      </div>
                    </div>
                  </p>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
